<template>
  <div class="columns is-gapless is-centered is-mobile">
    <div class="column is-12-mobile is-6-tablet is-6-desktop is-6-widescreen is-6-fullhd mb-4">
      <div class="container has-text-centered pt-6 px-5">
        <h3 class="title is-2 mb-5">
          Split bills, easily.
        </h3>
        <div class="mb-5 main-text-modifier">
          Splitting bills sucks, so we're here to help. We built the Best Bill Splitter, a free, no-install web application with features you've always wanted.
        </div>
        <button @click="$router.push({ name: 'Start' })" class="button is-large is-rounded is-info start-now-button-modifier mt-3">
          Start now
        </button>
      </div>
      <div class="container px-3 mx-2 mt-6">
        <figure class="image">
          <img class="main-image-modifier" src="../assets/homepage/bbshomepage7.png">
        </figure>
      </div>
      <div class="container has-text-centered pt-0 px-5">
        <h4 class="title is-4 mb-3 mt-6">
          Features at a glance
        </h4>
        <div class="has-text-centered mb-5">
          <span class="tag is-info is-small is-light mr-1 mb-1 is-unselectable">No installs</span>
          <span class="tag is-info is-small is-light mr-1 mb-1 is-unselectable">Split taxes fairly</span>
          <span class="tag is-info is-small is-light mr-1 mb-1 is-unselectable">Simplified settlements</span>
          <span class="tag is-info is-small is-light mr-1 mb-1 is-unselectable">Mark as settled</span>
          <span class="tag is-info is-small is-light mr-1 mb-1 is-unselectable">View-only mode</span>
          <span class="tag is-info is-small is-light mr-1 mb-1 is-unselectable">Multi-currency</span>
          <span class="tag is-info is-small is-light mr-1 mb-1 is-unselectable">Download as CSV</span>
          <span class="tag is-info is-small is-light mr-1 mb-1 is-unselectable">Delete data anytime</span>
        </div>

        <i class="fas fa-2x has-text-grey-light fa-angle-double-down scroll-down-animation"></i>

        <h4 class="title is-4 mb-3 mt-5">
          No installs, just a URL
        </h4>
        <div class="mb-5 feature-text-modifier">
          No installs whatsoever, just go to our site on any device with a decent browser. There's no need to convince your friends to install an app on their devices just for a dinner outing.
        </div>
        <div class="card px-4 pt-3 pb-5 has-background-info-light">
          <button class="button is-large is-outlined is-rounded is-size-7 break-word has-text-weight-semibold is-unselectable">
            <i class="fas fa-lock mr-2"></i>
            https://bestbillsplitter.com/.../
          </button>
        </div>

        <h4 class="title is-4 mb-3 mt-6">
          Split it right
        </h4>
        <div class="mb-5 feature-text-modifier">
          Just for convenience, most of us split extra charges like service charges and taxes equally among the party. But why should someone who spent just $5 on a dish pay the same amount as that hungry friend who spent $50?
        </div>
        <figure class="image">
          <img class="feature-image-modifier" src="../assets/homepage/bbshomepage1.png">
        </figure>
        
        <h4 class="title is-4 mb-3 mt-6">
          Minimise transfers
        </h4>
        <div class="mb-5 feature-text-modifier">
          Susan owes John $5 and John owes you $5... Why can't Susan transfer you $5 directly and save John the hassle? It's possible now.
        </div>
        <figure class="image">
          <img class="feature-image-modifier" src="../assets/homepage/bbshomepage4.png">
        </figure>

        <h4 class="title is-4 mb-3 mt-6">
          Settle payments easily
        </h4>
        <div class="mb-5 feature-text-modifier">
          There's no need to go back-and-forth between apps to check the amount split, payment details and letting your friends know that you've paid.
        </div>
        <figure class="image">
          <img class="feature-image-modifier" src="../assets/homepage/bbshomepage5.png">
        </figure>

        <h4 class="title is-4 mb-3 mt-6">
          Just here for the numbers?
        </h4>
        <div class="mb-5 feature-text-modifier">
          If you prefer just splitting your bills here but settling everything else in your personal chat app, you can. Use our copy-all-data function and paste it as text, beautifully formatted.
        </div>
        <figure class="image">
          <img class="feature-image-modifier" src="../assets/homepage/bbshomepage2.png">
        </figure>

        <h4 class="title is-4 mb-3 mt-6">
          Don't want unexpected changes?
        </h4>
        <div class="mb-5 feature-text-modifier">
          View-only mode prevents people from making changes. Different share URLs allows you to control who can make changes, and who can't.
        </div>
        <figure class="image">
          <img class="feature-image-modifier" src="../assets/homepage/bbshomepage3.png">
        </figure>

        <h4 class="title is-4 mb-3 mt-6">
          Travelled the world?
        </h4>
        <div class="mb-5 feature-text-modifier">
           Add 1, 2, or as many currencies you need and convert them to one. We'll provide the conversion rates automagically.
        </div>
        <figure class="image">
          <img class="feature-image-modifier" src="../assets/homepage/bbshomepage8.png">
        </figure>

        <h4 class="title is-4 mb-3 mt-6">
          Privacy
        </h4>
        <div class="mb-5 feature-text-modifier">
          We are here only to split your bills. Once you're done, your data is not needed or used on our servers. By default, your data is deleted 14 days after it has last been seen. If you prefer, you can delete it immediately at anytime.
        </div>
        <figure class="image">
          <img class="feature-image-modifier" src="../assets/homepage/bbshomepage6.png">
        </figure>

        <h4 class="title is-4 mb-5 mt-6 pt-6">
          Try, it's free anyway.
        </h4>
        <button @click="$router.push({ name: 'Start' })" class="button is-large is-rounded is-info start-now-button-modifier">
          Start now
        </button>

        <div class="pt-6 my-6"></div>

        <div class="container">
          <div class="is-size-7 has-text-grey-light">
            Made by M Afiq, out of his frustration of splitting bills.
          </div>
          <div
          class="is-size-7 mt-4 is-underlined is-unselectable is-clickable"
          >
          <a
          class="has-text-grey-light"
          href="https://www.linkedin.com/in/m-afiq/"
          target="_blank">
          <u>LinkedIn</u>
          </a> 
          </div>
          <div @click="togglePrivacyNotice()" class="is-size-7 has-text-grey-light mt-2 is-underlined is-unselectable is-clickable">
            <u>Privacy Policy</u>
          </div>
        </div>
      </div>
      <div class="container pt-0 mb-5 m-0">
        <article v-show="showPrivacyNotice" class="message pt">
          <div class="message-body">
            <PrivacyNotice />
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import PrivacyNotice from '@/components/PrivacyNotice'


export default {
  metaInfo: {
    title: 'Best Bill Splitter | The best way to split your bills',
    meta: [
      {
        'name': 'description',
        'content': 'The free, fastest, and easiest way to let your friends know how much to pay, where to pay, and who has paid. No app installs required.'
      }
    ]
  },
  components: {
    PrivacyNotice
  },
  data() {
    return {
      showPrivacyNotice: false
    }
  },
  methods: {
    togglePrivacyNotice() {
      this.showPrivacyNotice = !this.showPrivacyNotice
    }
  }
}
</script>

<style>
.main-text-modifier {
  font-size: clamp(0.6rem, 0.6rem + 2vw, 1rem);
}

.main-image-modifier {
  margin: auto;
  width: clamp(250px, 70vw, 400px) !important
}

.feature-text-modifier {
  font-size: clamp(0.8rem, 0.6rem + 1vw, 1rem);
}

.feature-image-modifier {
  margin: auto;
  width: clamp(250px, 70vw, 400px) !important
}

.start-now-button-modifier {
  background: rgb(0,176,255) !important;
  background: linear-gradient(156deg, rgba(0,176,255,1) 0%, rgba(0,122,203,1) 0%, rgba(0,134,223,1) 100%) !important;
  border: 0 !important;
}

.scroll-down-animation {
  animation-name: scroll-down;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in;
}

@keyframes scroll-down {
  0% {
    transform:translateY(0);
  }
  50% {
    transform:translateY(10px);
  }
  100% {
    transform:translateY(0);
  }
}
</style>